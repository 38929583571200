import { useCallback, useEffect, useRef } from 'react'

import { useAuthContext } from '../context/AuthContext'
import useFetchOnFocus from './useFetchOnFocus'

import {
    RealtimeChannel,
    RealtimePostgresDeletePayload,
    RealtimePostgresInsertPayload,
    RealtimePostgresUpdatePayload,
    Session,
} from '@supabase/supabase-js'

/* Hooks */
import { useGetTranslations } from '@/i18n/index'

import { useApplicationStore } from '@/context/ApplicationStore.tsx'
import { useApplicationContext } from '@/context/ApplicationContext'

/* Types*/
import REAL_TIME_ACTION_TYPES, { NOTIFICATION_TYPE } from '@/constants/constants.ts'

/* Enums */
import { ENUM_ACTION_TYPES } from '@/enums/Enums'

/* Utils */
import { getLocalizedTableData, mapNotification } from '@/utils/functionUtils'
import { vanillaTRPC as trpc } from '@/utils/trpc.ts'


import {
    BroadcastMessage,
    FloorLocalizationT,
    NotificationT,
    OrderT,
    ReservationT,
    SectionLocalizationT,
    TableT,
} from '@/types/globalTypes.js'
import { getDefaultTableStructure } from '@/utils/stateUtils'

interface UseRealtimeCustomerOrderSubscriptionParams {
    loading: boolean
    session: Session
}

const useRealtimeCustomerOrderSubscription = ({
    loading,
    session,
}: UseRealtimeCustomerOrderSubscriptionParams) => {
    const { TOAST_NOTIFICATIONS_TEXT } = useGetTranslations()
    const { supabaseClient } = useAuthContext()
    const { setUserNotifications, setToastNotification, applicationState } = useApplicationContext()

    const { state, dispatch } = useApplicationStore((state) => state.tablePage)

    const selectedLanguage = applicationState.selectedLanguage
    const { reservation } = state
    let realtimeBroadCastChannel = useRef<RealtimeChannel | null>()

    const initChannel = useCallback(() => {
        if (
            reservation === null ||
            reservation === undefined ||
            reservation.id === '' ||
            loading ||
            !session
        ) {
            return
        }

        if (realtimeBroadCastChannel.current) {
            supabaseClient.removeChannel(realtimeBroadCastChannel.current)
        }

        realtimeBroadCastChannel.current = supabaseClient
            .channel('realtime_broadcast_channel')
            .on('broadcast', { event: 'INSERT' }, (payload) => handleRealtimeEvent(payload))
            .on('broadcast', { event: 'DELETE' }, (payload) => handleRealtimeEvent(payload))
            .on('broadcast', { event: 'UPDATE' }, (payload) => handleRealtimeEvent(payload))
            .subscribe((status) => {
                if (status === 'SUBSCRIBED') {
                    console.log('Connected!')
                }
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setToastNotification, supabaseClient, loading])

    useEffect(() => {
        initChannel()

        return () => {
            if (realtimeBroadCastChannel.current) {
                supabaseClient.removeChannel(realtimeBroadCastChannel.current)
            }
        }
    }, [initChannel, supabaseClient])

    const reinitChannel = () => {
        if (realtimeBroadCastChannel.current) {
            supabaseClient.removeChannel(realtimeBroadCastChannel.current)
        }
        initChannel()
    }

    useFetchOnFocus(reinitChannel, [initChannel, supabaseClient])

    let messageCounter = 0
    let debounceTimer = null
    const DEBOUNCE_TIME = 1000 // 1 second, adjust as needed

    const handleRealtimeEvent = (message: BroadcastMessage) => {
        const eventName = message.payload.action_type
        const payload = message.payload

        // Increment the counter
        messageCounter++

        // Clear the existing timer
        clearTimeout(debounceTimer)

        // Set a new timer
        debounceTimer = setTimeout(async () => {
            console.log(`Broadcasted message after ${messageCounter} events.`)
            messageCounter = 0 // Reset counter after sending the message
        }, DEBOUNCE_TIME)

        switch (eventName) {
            case REAL_TIME_ACTION_TYPES.RESERVATIONS_UPDATE:
                handleUpdateReservation(payload)
                break
            case REAL_TIME_ACTION_TYPES.RESERVATIONS_DELETE:
                handleDeleteReservation(payload)
                break
            case REAL_TIME_ACTION_TYPES.ORDERS_INSERT:
                handleInsertOrders(payload)
                break
            case REAL_TIME_ACTION_TYPES.ORDERS_UPDATE:
                handleUpdateOrders(payload)
                break
            case REAL_TIME_ACTION_TYPES.ORDERS_DELETE:
                handleDeleteOrders(payload)
                break
            case REAL_TIME_ACTION_TYPES.VENUE_TABLES_UPDATE:
                handleUpdateTable(payload)
                break
            case REAL_TIME_ACTION_TYPES.NOTIFICATIONS_INSERT:
                handleInsertNotification(payload)
                break
            case REAL_TIME_ACTION_TYPES.NOTIFICATIONS_UPDATE:
                handleUpdateNotification(payload)
                break
            case REAL_TIME_ACTION_TYPES.NOTIFICATIONS_DELETE:
                handleDeleteNotification(payload)
                break
            case REAL_TIME_ACTION_TYPES.FLOORS_LOCALIZATION_INSERT:
                handleInsertFloors(payload)
                break
            case REAL_TIME_ACTION_TYPES.FLOORS_LOCALIZATION_UPDATE:
                handleUpdateFloors(payload)
                break
            case REAL_TIME_ACTION_TYPES.FLOORS_LOCALIZATION_DELETE:
                handleDeleteFloors(payload)
                break
            case REAL_TIME_ACTION_TYPES.SECTIONS_LOCALIZATION_INSERT:
                handleInsertSections(payload)
                break
            case REAL_TIME_ACTION_TYPES.SECTIONS_LOCALIZATION_UPDATE:
                handleUpdateSections(payload)
                break
            case REAL_TIME_ACTION_TYPES.SECTIONS_LOCALIZATION_DELETE:
                handleDeleteSections(payload)
                break
        }
    }

    const handleUpdateOrders = (payload: RealtimePostgresUpdatePayload<OrderT>) => {
        if (payload.new.reservation_id !== reservation.id) return
        const updatedOrder = payload.new
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_ORDER,
            payload: { updatedOrder },
        })
    }

    const handleInsertOrders = (payload: RealtimePostgresInsertPayload<OrderT>) => {
        if (payload.new.reservation_id !== reservation.id) return
        const newOrder = payload.new
        dispatch({
            type: ENUM_ACTION_TYPES.ADD_ORDER,
            payload: { newOrder },
        })
    }

    const handleDeleteOrders = (payload: RealtimePostgresDeletePayload<OrderT>) => {
        if (payload.old.reservation_id !== reservation.id) return
        const deletedOrder = payload.old
        dispatch({
            type: ENUM_ACTION_TYPES.DELETE_CUSTOMER_ORDER,
            payload: { orderId: deletedOrder.id },
        })
    }

    const handleUpdateReservation = async (
        payload: RealtimePostgresUpdatePayload<ReservationT>
    ) => {
        try {

            if (payload.new.id !== reservation.id) return
            const updatedReservation = payload.new

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_RESERVATION,
                payload: { updatedReservation },
            })

            if (updatedReservation.table_id === null && updatedReservation.is_static === false) {
                dispatch({
                    type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_TABLE,
                    payload: {
                        updatedTable: getDefaultTableStructure(),
                    },
                })
            } else if (
                updatedReservation.table_id !== state.reservation.table_id &&
                updatedReservation.is_static === false
            ) {

                const fetchedTableInstance = await trpc.tables.getById.query(updatedReservation.table_id)

                const newTableData = getLocalizedTableData(
                    fetchedTableInstance as TableT,
                    state.sections,
                    state.floors,
                    selectedLanguage
                )

                dispatch({
                    type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_TABLE,
                    payload: { updatedTable: newTableData },
                })
            }
        } catch (error) {

            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
        }
    }

    const handleDeleteReservation = (payload: RealtimePostgresDeletePayload<ReservationT>) => {
        if (payload.old.id !== reservation.id) return
        dispatch({
            type: ENUM_ACTION_TYPES.DELETE_CUSTOMER_RESERVATION,
            payload: { deletedReservationId: payload.old.id },
        })
    }

    const handleUpdateTable = (payload: RealtimePostgresUpdatePayload<TableT>) => {
        const updatedTable = payload.new

        if (
            (updatedTable.id !== reservation.table_id && reservation.table_id) ||
            (updatedTable.static_reservation_id !== reservation.id && reservation.is_static)
        ) {
            return
        }

        const newTableData = getLocalizedTableData(
            updatedTable,
            state.sections,
            state.floors,
            selectedLanguage
        )

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_CUSTOMER_TABLE,
            payload: { updatedTable: newTableData },
        })
    }

    const handleUpdateNotification = useCallback(
        (payload: RealtimePostgresUpdatePayload<NotificationT>) => {
            if (payload.new.recipient_id !== reservation.id) return

            const updatedNotification = payload.new

            setUserNotifications((prevState) => {
                return prevState.map((notification) => {
                    if (notification.id === updatedNotification.id) {
                        let mappedUpdatedNotification = mapNotification(
                            updatedNotification,
                            state.orders,
                            [state.reservation],
                            [state.tableData]
                        )
                        return mappedUpdatedNotification
                    }
                    return notification
                })
            })
        },
        [reservation, setUserNotifications, state.orders, state.reservation, state.tableData]
    )

    const handleInsertNotification = useCallback(
        (payload: RealtimePostgresInsertPayload<NotificationT>) => {
            if (payload.new.recipient_id !== reservation.id) return

            const newNotification = payload.new

            const mappedNewNotification = mapNotification(
                newNotification,
                state.orders,
                [state.reservation],
                [state.tableData]
            )

            setUserNotifications((prevState) => [mappedNewNotification, ...prevState])
        },
        [reservation, state.orders, state.reservation, state.tableData, setUserNotifications]
    )

    const handleDeleteNotification = useCallback(
        (payload: RealtimePostgresDeletePayload<NotificationT>) => {
            if (payload.old.recipient_id !== reservation.id) return

            const deletedNotification = payload.old

            setUserNotifications((prevState) => {
                return prevState.filter(
                    (notification) => notification.id !== deletedNotification.id
                )
            })
        },
        [reservation, setUserNotifications]
    )

    const handleInsertFloors = (payload: RealtimePostgresInsertPayload<FloorLocalizationT>) => {
        const newFloorLocalization = payload.new
        dispatch({
            type: ENUM_ACTION_TYPES.INSERT_FLOOR,
            payload: { newFloorLocalization },
        })
    }

    const handleUpdateFloors = (payload: RealtimePostgresUpdatePayload<FloorLocalizationT>) => {
        const updatedFloorLocalization = payload.new
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_FLOOR,
            payload: { updatedFloorLocalization },
        })
    }

    const handleDeleteFloors = (payload: RealtimePostgresDeletePayload<FloorLocalizationT>) => {
        const floorId = payload.old.id
        dispatch({
            type: ENUM_ACTION_TYPES.DELETE_FLOOR,
            payload: { floorId },
        })
    }

    const handleUpdateSections = (payload: RealtimePostgresUpdatePayload<SectionLocalizationT>) => {
        const updatedSectionLocalization = payload.new
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_SECTION,
            payload: { updatedSectionLocalization },
        })
    }

    const handleInsertSections = (payload: RealtimePostgresInsertPayload<SectionLocalizationT>) => {
        const newSectionLocalization = payload.new
        dispatch({
            type: ENUM_ACTION_TYPES.INSERT_SECTION,
            payload: { newSectionLocalization },
        })
    }

    const handleDeleteSections = (payload: RealtimePostgresDeletePayload<SectionLocalizationT>) => {
        const sectionId = payload.old.id
        dispatch({
            type: ENUM_ACTION_TYPES.DELETE_SECTION,
            payload: { sectionId },
        })
    }
}

export default useRealtimeCustomerOrderSubscription
